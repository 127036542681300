import { ScrollShadow } from '@nextui-org/scroll-shadow'
import { Spacer } from '@nextui-org/spacer'

import { useSidebar } from '@/hooks'
import { cn } from '@/utils'

import { SidebarAccountSwitcher } from './AccountSwitcher'
import { SidebarBrand } from './Brand'
import { SidebarCollapseButton } from './CollapseButton'
import { SidebarThemeSelector } from './ThemeSelector'
import { SidebarUser } from './User'
import {
  WorkspaceNavigation,
  FavoritesNavigation,
  NavSectionDivider,
} from './nav-sections'

const Sidebar = () => {
  const { isCompact, isHidden, canHide, setIsSidebarHidden } = useSidebar()

  return (
    <>
      {canHide && !isHidden && (
        <div
          className={cn(
            'fixed inset-0 bg-black/10 dark:bg-gray-900/20 z-40 transition-opacity ease-linear',
            isHidden ? 'opacity-0' : 'opacity-100',
          )}
          onClick={() => setIsSidebarHidden(true)}
        />
      )}
      <div
        className={cn(
          'flex h-screen min-h-0 w-80 max-w-[330px] flex-col px-3.5 py-5 transition-all ease-in-out',
          {
            'w-16 items-center px-2 py-5': isCompact,
            'absolute top-0 left-0 z-50 border-r border-divider/15 dark:border-divider/10 shadow-sidebarLight dark:shadow-sidebarDark bg-gray-100 dark:bg-black':
              canHide,
            relative: !canHide,
            '-ml-72 -translate-x-72': isHidden,
          },
        )}
      >
        <div className="flex items-center justify-between">
          <SidebarBrand isCompact={isCompact} />

          {!isCompact && <SidebarCollapseButton />}
        </div>

        <Spacer y={8} />

        <SidebarAccountSwitcher isCompact={isCompact} />

        <ScrollShadow className="h-full max-h-full pb-3 mt-4 overflow-x-hidden">
          {isCompact && <NavSectionDivider />}

          <WorkspaceNavigation isCompact={isCompact} />

          {isCompact && <NavSectionDivider />}

          <FavoritesNavigation isCompact={isCompact} />
        </ScrollShadow>

        <Spacer y={2} />

        <footer
          className={cn('mt-auto flex flex-col', {
            'items-center': isCompact,
          })}
        >
          <SidebarThemeSelector isCompact={isCompact} />

          <Spacer y={4} />

          <SidebarUser isCompact={isCompact} />
        </footer>
      </div>
    </>
  )
}

Sidebar.displayName = 'Sidebar'

export default Sidebar
