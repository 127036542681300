import { Session } from '@supabase/supabase-js'
import { useQueryClient } from '@tanstack/react-query'
import { randomUUID } from 'crypto'
import { H } from 'highlight.run'
import { useRouter } from 'next/router'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { supabaseComponentClient } from '@/plugins/supabase'
import { useUser } from '@/hooks/queries/useUser'
import { SupabaseUserMetadata } from '@/models'
import { apiClient } from '@/services/api/client'

import { AuthContext } from './authContext'

const REFRESHING_TIME_GAP_BEFORE_EXPIRE_IN_SECS = 10

export function AuthContextProvider({ children }: PropsWithChildren) {
  const router = useRouter()

  const [session, setSession] = useState<Session | null>()
  // TODO: should be a state machine instead of too many flags - useState
  const [isInitialLogIn, setIsInitialLogIn] = useState(true)

  const [isSigningOut, setIsSigningOut] = useState(false)

  const queryClient = useQueryClient()

  const { data: user } = useUser()

  const userMetadata = useMemo(
    () => (session?.user?.user_metadata || {}) as SupabaseUserMetadata,
    [session],
  )

  const updateToken = useCallback(
    async (
      newAccessToken: string,
      onSuccess?: () => void,
      newRefreshToken?: string,
    ) => {
      const refreshToken = newRefreshToken || session?.refresh_token
      if (!refreshToken) return

      const { error } = await supabaseComponentClient.auth.setSession({
        access_token: newAccessToken,
        refresh_token: refreshToken,
      })

      if (error) {
        console.error('Failed to update token', error)
        return
      }

      onSuccess?.()
    },
    [session],
  )

  const getSessionAsync = useCallback(async () => {
    const { data: session, error } =
      await supabaseComponentClient.auth.getSession()

    if (error || !session) {
      console.error('Failed to get session', error)
      setSession(null)
      return
    }
  }, [])

  const scheduleTokenRefresh = useCallback((rawSession: Session) => {
    const expirationTime =
      rawSession.expires_in - REFRESHING_TIME_GAP_BEFORE_EXPIRE_IN_SECS
    const refreshTimeInMs = expirationTime * 1000

    const scheduleTimeout = setTimeout(async () => {
      const { error } = await supabaseComponentClient.auth.refreshSession()

      if (error) {
        console.error('Failed to refresh session - Signing out', error)
        supabaseComponentClient.auth.signOut()
      }
    }, refreshTimeInMs)

    return scheduleTimeout
  }, [])

  useEffect(() => {
    getSessionAsync()

    supabaseComponentClient.auth.stopAutoRefresh()

    const {
      data: { subscription },
    } = supabaseComponentClient.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION') {
        setSession(session)
        setIsInitialLogIn(false)
      } else if (event === 'SIGNED_IN') {
        setSession(session)
      } else if (event === 'TOKEN_REFRESHED') {
        setSession(session)
        queryClient.cancelQueries()
      } else if (event === 'SIGNED_OUT') {
        setSession(null)
        queryClient.clear()
        setIsSigningOut(false)
        router.replace('/login')
      } else if (event === 'USER_UPDATED') {
        router.replace(`/`)
      }

      if (session?.user?.email) {
        H.identify(session.user.email, {
          id: session.user.id,
        })
      } else if (randomUUID) {
        H.identify('UNKNOWN_USER', {
          id: randomUUID(),
        })
      }
    })

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!session) return

    const scheduleStopper = scheduleTokenRefresh(session)

    return () => {
      !!scheduleStopper && clearTimeout(scheduleStopper)
    }
  }, [session, scheduleTokenRefresh])

  const jwt = useMemo(() => session?.access_token, [session?.access_token])

  const getCurrentToken = useCallback(() => session?.access_token, [session])

  const intercomData = useMemo(() => {
    return {
      app_id: 'jvogfp1x',
      ...(user
        ? {
            email: user?.email,
            user_id: user?.id,
          }
        : {}),
    }
  }, [user])

  useEffect(() => {
    if (!session) queryClient.clear()
  }, [session, queryClient])

  useEffect(() => {
    if (window.Intercom) window.Intercom('boot', intercomData)
  }, [intercomData])

  useEffect(() => {
    if (!window.Intercom) return

    const handleRouteChange = () => {
      window.Intercom('update', intercomData)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, intercomData])

  useEffect(() => {
    if (jwt) {
      apiClient.setAuthorizationHeader(jwt)
    }
  }, [jwt])

  return (
    <AuthContext.Provider
      value={{
        jwt,
        userMetadata,
        session,
        updateToken,
        isInitialLogIn,
        isSigningOut,
        setIsSigningOut,
        getCurrentToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
