import { Select, SelectItem, SelectSection } from '@nextui-org/select'
import React, { memo, useCallback, useMemo } from 'react'

import { useSwitchAccount } from '@/hooks/mutations'
import { useAccounts } from '@/hooks/queries'
import { useUser } from '@/hooks/queries/useUser'
import { SidebarChildrenProps } from '@/models/components'
import { cn } from '@/utils'
import {
  ChevronDownIcon,
  UnfoldMoreIcon,
  UserGroupIcon,
} from '@/components/ui/icons'

export const SidebarAccountSwitcher = memo(
  ({ isCompact }: SidebarChildrenProps) => {
    const { data: accounts, isFetching: isFetchingAccounts } = useAccounts()

    const { data: user, isFetching: isFetchingUser } = useUser()

    const { mutateAsync } = useSwitchAccount()

    const currentAccountId = useMemo(() => [user?.accountId || ''], [user])

    const availableAccounts = useMemo(() => {
      return [
        {
          value: '0',
          label: 'Switch team',
          items:
            accounts?.map(account => ({
              value: account.id,
              label: account.companyName || account.name,
            })) || [],
        },
      ]
    }, [accounts])

    const onSelectChange = useCallback(
      async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const accountId = event.target.value
        await mutateAsync(accountId)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    return (
      <div>
        <Select
          selectionMode="single"
          disallowEmptySelection
          disableSelectorIconRotation
          isLoading={isFetchingAccounts || isFetchingUser}
          aria-label="Select workspace"
          className="justify-center px-1"
          classNames={{
            innerWrapper: isCompact ? 'block' : 'inline-flex',
            trigger: cn(
              'ui min-h-14 h-14 bg-transparent border-small border-default-200 dark:border-default-100 data-[hover=true]:border-default-500 dark:data-[hover=true]:border-default-200 data-[hover=true]:bg-transparent',
              { 'border-none relative pt-2': isCompact },
            ),
          }}
          defaultSelectedKeys={currentAccountId}
          selectedKeys={currentAccountId}
          disabledKeys={currentAccountId}
          items={availableAccounts}
          placeholder="Select team"
          popoverProps={{
            placement: isCompact ? 'right' : 'bottom',
            className: isCompact ? 'min-w-[250px] w-fit' : undefined,
          }}
          onChange={onSelectChange}
          renderValue={items => {
            if (isCompact) return null

            return items.map(item => (
              <div key={item.key} className="ml-1 flex flex-col gap-y-0.5">
                <span className="text-tiny leading-4">{item.data?.label}</span>
                <span className="text-tiny text-default-400">Team</span>
              </div>
            ))
          }}
          selectorIcon={
            isCompact ? (
              <ChevronDownIcon
                className="text-default-500 absolute"
                style={{ bottom: 4, right: 12 }}
              />
            ) : (
              <UnfoldMoreIcon className="text-default-500" />
            )
          }
          startContent={
            <div
              className={cn(
                'relative h-10 w-10 flex-none rounded-full border-small border-default-300',
                {
                  '-mt-1 ml-1 flex h-12 w-12 items-center justify-center border-none hover:bg-primary-500/20':
                    isCompact,
                },
              )}
            >
              <UserGroupIcon
                className={cn('text-default-500', { 'ml-2 mt-2': !isCompact })}
                // width={24}
              />
            </div>
          }
        >
          {section => (
            <SelectSection
              hideSelectedIcon
              key={section.value}
              aria-label={section.label}
              items={section.items}
              title={section.label}
            >
              {/* @ts-ignore */}
              {item => (
                <SelectItem
                  key={item.value}
                  aria-label={item.label}
                  textValue={item.label}
                  value={item.value}
                >
                  <div className="flex flex-row items-center justify-between gap-1">
                    <span>{item.label}</span>
                    <div className="flex h-6 w-6 items-center justify-center rounded-full border-small border-default-300">
                      <UserGroupIcon className="text-default-500 h-4 w-4" />
                    </div>
                  </div>
                </SelectItem>
              )}
            </SelectSection>
          )}
        </Select>
      </div>
    )
  },
)

SidebarAccountSwitcher.displayName = 'SidebarAccountSwitcher'
