import {
  Dropdown,
  DropdownItem,
  DropdownMenuProps,
  DropdownProps,
  DropdownSection,
  DropdownSectionProps,
  DropdownTrigger,
  DropdownMenu,
} from '@nextui-org/dropdown'
import { MenuItemProps } from '@nextui-org/menu/dist/menu-item'
import { CollectionChildren } from '@react-types/shared/src/collections'
import { useMemo } from 'react'

export type UIItemConfig<K extends object> = Omit<
  MenuItemProps<K>,
  'item' | 'state'
>

export type UIDropdownProps<K extends object> = Omit<
  DropdownMenuProps<K>,
  'children'
> & {
  items?: UIItemConfig<K>[]
  sections?: ({ items: UIItemConfig<K>[] } & Omit<
    DropdownSectionProps<K>,
    'children'
  >)[]
  dropdownProps?: Omit<DropdownProps, 'children'>
  triggerContent?: JSX.Element
}

const UIDropdown = <K extends object>({
  items,
  sections,
  dropdownProps,
  triggerContent,
  ...dropdownMenu
}: UIDropdownProps<K>) => {
  const { base: customBaseClasses, ...itemClasses } =
    dropdownMenu.itemClasses || {}

  const children: CollectionChildren<K> | null = useMemo(() => {
    if (sections) {
      return sections.map(({ items, ...section }) => (
        <DropdownSection
          key={section.key}
          {...section}
          classNames={{ base: 'last-of-type:mb-0' }}
        >
          {items.map(item => (
            <DropdownItem key={item.key} {...item} />
          ))}
        </DropdownSection>
      ))
    }

    if (items) {
      return items.map(item => <DropdownItem key={item.key} {...item} />)
    }

    return null
  }, [sections, items])

  if (!children) return null

  return (
    <Dropdown
      {...dropdownProps}
      radius="sm"
      className="border-small border-divider shadow-md dark:dropdownDark"
      classNames={{
        content: 'bg-background',
      }}
    >
      {triggerContent && <DropdownTrigger>{triggerContent}</DropdownTrigger>}

      <DropdownMenu
        {...dropdownMenu}
        itemClasses={{
          base: [
            'text-default-500',
            'transition-opacity',
            'data-[hover=true]:bg-default-100 dark:data-[hover=true]:bg-default-50 data-[selectable=true]:focus:bg-default-50 data-[pressed=true]:opacity-70 data-[focus=true]:text-default-foreground data-[focus-visible=true]:z-0 data-[focus-visible=true]:outline-0 data-[focus-visible=true]:outline-none data-[focus-visible=true]:outline-offset-0 data-[focus-visible=true]:dark:ring-offset-background-content1',
            ...Array.from(customBaseClasses || []),
          ],
          title: [
            'text-xs',
            'text-default-500',
            'group-hover:text-default-foreground',
          ],

          description: ['group-hover:text-default-500'],
          ...itemClasses,
        }}
      >
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}

UIDropdown.displayName = 'UIDropdown'

export default UIDropdown
