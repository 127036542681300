import React, { forwardRef } from 'react'

import { cn } from '@/utils'

import { DragDropVerticalIcon } from '../icons'

const DndHandle = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div ref={ref} className={cn('cursor-grab', props.className)} {...props}>
      <DragDropVerticalIcon
        color="currentColor"
        className="w-full h-full"
        // width="12"
        // viewBox="0 0 20 20"
      />
      {/* <svg
        viewBox="0 0 20 20"
        width="12"
        color="currentColor"
        fill="currentColor"
      >
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg> */}
    </div>
  )
})

DndHandle.displayName = 'Docsum.DndHandle'

export default DndHandle
