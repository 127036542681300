import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect } from 'react'

import { useAuthContext, useCurrentUser } from '@/hooks'

import { Loader } from './Loader'
import PublicLayout from './Public'
import PrivateLayout from './Private'

const publicPages = ['/login', '/pass-setup']

const protectedPages = ['/pass-reset']

const isPrivatePage = (pathname: string) =>
  !publicPages.includes(pathname) && !protectedPages.includes(pathname)

export const RootLayout = ({ children }: PropsWithChildren) => {
  const { session, isSigningOut, isInitialLogIn } = useAuthContext()

  const router = useRouter()

  const {
    shouldCompleteSignUp,
    isLoading: isLoadingUser,
    isFirstLoad,
  } = useCurrentUser()

  const pathname = usePathname()

  const isRootPath = pathname === '/'

  useEffect(() => {
    if (!isInitialLogIn && !session && isPrivatePage(pathname)) {
      router.replace('/login')
    }
  }, [isInitialLogIn, pathname, router, session])

  if (pathname === '/pass-setup') {
    return <PublicLayout>{children}</PublicLayout>
  }

  if (!session) {
    return (
      <PublicLayout>
        {isInitialLogIn ||
        isLoadingUser ||
        ((isSigningOut || !publicPages.includes(pathname)) && !isRootPath) ? (
          <Loader />
        ) : (
          children
        )}
      </PublicLayout>
    )
  }

  if (
    (isLoadingUser && isFirstLoad) ||
    shouldCompleteSignUp ||
    protectedPages.includes(pathname) ||
    pathname === '/login'
  ) {
    return <PublicLayout>{isLoadingUser ? <Loader /> : children}</PublicLayout>
  }

  return <PrivateLayout>{children}</PrivateLayout>
}

RootLayout.displayName = 'RootLayout'
