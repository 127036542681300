import { FeaturesResponse } from '@/models/api'

import BaseApi from './base'
import { apiClient, getHeaders, type ApiRequestConfig } from './client'

export class FeaturesApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'features'
  }

  async getFeatures({ signal, ...configProps }: ApiRequestConfig = {}) {
    return apiClient.get<FeaturesResponse>(this.resourcePath, '', {
      signal,
      ...getHeaders(configProps),
    })
  }
}

const featuresApi = new FeaturesApi()

export default featuresApi
