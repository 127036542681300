import React from 'react'

const NavSectionDivider = () => (
  <div className="px-1">
    <div
      className="shrink-0 bg-divider border-none w-full h-divider mt-0 mb-1"
      role="separator"
    />
  </div>
)

export default NavSectionDivider
