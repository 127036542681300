import React, { HTMLAttributes, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { cn } from '@/utils'

import NavSectionHeader, { type NavSectionHeaderProps } from './Header'

interface NavSectionProps
  extends Pick<
      NavSectionHeaderProps,
      'isCompact' | 'title' | 'actions' | 'CompactIcon'
    >,
    Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string
  topContent?: React.ReactNode
  topContentClassName?: string
}

const NavSection = ({
  isCompact,
  title,
  actions,
  CompactIcon,
  topContent,
  children,
  ...divProps
}: NavSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleExpanded = () => setIsExpanded(prev => !prev)

  return (
    <div
      className={cn(
        'group',
        isCompact ? 'flex justify-center flex-col p-1 w-full mb-2' : '-ml-2',
      )}
      {...divProps}
    >
      <NavSectionHeader
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isCompact={isCompact}
        title={title}
        actions={actions}
        CompactIcon={CompactIcon}
        onClick={toggleExpanded}
      />

      {topContent}

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div className={!isCompact ? 'pb-2 ml-2 pt-1' : undefined}>
              <div
                className={!isCompact ? 'overflow-clip relative' : undefined}
              >
                {children}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default NavSection
