import { useQuery } from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'
import { featuresApi } from '@/services/api'
import { DEFAULT_STATIC_DATA_CACHE_20_MIN_IN_MS } from '@/constants'

export function useGetFeatures() {
  return useQuery({
    queryKey: QKEY.FEATURES,
    queryFn: ({ signal }) => featuresApi.getFeatures({ signal }),
    gcTime: DEFAULT_STATIC_DATA_CACHE_20_MIN_IN_MS,
    staleTime: DEFAULT_STATIC_DATA_CACHE_20_MIN_IN_MS,
  })
}
