import { useMutation } from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'
import { FavoriteViewsNavigationPatchRequest } from '@/models/api'
import { navigationApi } from '@/services/api'

import { useInvalidateFavoritesNavigation } from './useInvalidateFavoritesNavigation'

export function usePatchFavoritesNavigation() {
  const invalidateNavigation = useInvalidateFavoritesNavigation()

  return useMutation({
    mutationKey: QKEY.VIEWS_NAVIGATION.patchFavoritesNavigation,
    mutationFn: (payload: FavoriteViewsNavigationPatchRequest) =>
      navigationApi.patchFavoritesNavigation(payload),
    onSuccess: async () => {
      await invalidateNavigation()
    },
  })
}
