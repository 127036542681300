import {
  FavoriteViewsNavigation,
  FavoriteViewsNavigationPatchRequest,
  WorkspaceNavigation,
} from '@/models/api'

import BaseApi from './base'
import { apiClient, ApiRequestConfig, getHeaders } from './client'

export class NavigationApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'navigation'
  }

  async getWorkspaceNavigation({
    signal,
    ...configProps
  }: ApiRequestConfig = {}) {
    return apiClient.get<WorkspaceNavigation>(this.resourcePath, 'workspace', {
      signal,
      ...getHeaders(configProps),
    })
  }

  async getFavoritesNavigation({
    signal,
    ...configProps
  }: ApiRequestConfig = {}) {
    return apiClient.get<FavoriteViewsNavigation>(
      this.resourcePath,
      'favorites',
      {
        signal,
        ...getHeaders(configProps),
      },
    )
  }

  async patchFavoritesNavigation({
    id,
    changes,
  }: FavoriteViewsNavigationPatchRequest) {
    return apiClient.patch<FavoriteViewsNavigation>(
      `${this.resourcePath}/favorites`,
      id.toString(),
      changes,
    )
  }
}

const navigationApi = new NavigationApi()

export default navigationApi
