import { memo, useCallback, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { UIInput } from '@/components/ui'
import { cn } from '@/utils'

interface UITreeInputProps {
  startContent?: React.ReactNode
  placeholder?: string
  defaultValue?: string
  inputWrapperClassName?: string
  inputClassName?: string
  onClose(): void
  onPressEnter?(name: string): void | Promise<void>
}

const UITreeInput = memo(
  ({
    defaultValue,
    startContent,
    placeholder,
    inputWrapperClassName,
    inputClassName,
    onClose,
    onPressEnter,
  }: UITreeInputProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [newValue, setNewNewValue] = useState(defaultValue ?? '')

    useOnClickOutside(wrapperRef, onClose)

    const onChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewNewValue(e.target.value)
      },
      [],
    )

    const onKeyDownHandler = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          !!newValue && onPressEnter?.(newValue)
          onClose()
        } else if (e.key === 'Escape') {
          onClose()
        }
      },
      [newValue, onClose, onPressEnter],
    )

    return (
      <div ref={wrapperRef} className="w-full">
        <UIInput
          autoFocus
          fullWidth
          size="sm"
          variant="flat"
          placeholder={placeholder}
          startContent={startContent}
          classNames={{
            input: cn('text-xs', inputClassName),
            inputWrapper: cn('h-7 min-h-7', inputWrapperClassName),
          }}
          value={newValue}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
        />
      </div>
    )
  },
)

UITreeInput.displayName = 'Docsum.UITreeInput'

export default UITreeInput
