import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

import { API_URL } from '@/constants/api'

function handleResponse<T>(response: AxiosResponse<T>) {
  // TODO: space for possible transformations, parsing, etc.
  return response.data
}

export type ApiRequestConfig = Pick<AxiosRequestConfig, 'signal'> & {
  token?: string
}

export const buildPath = (segments: string[]) =>
  segments.filter(Boolean).join('/')

export const getHeaders = ({ token }: ApiRequestConfig) =>
  token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {}

export type ApiClientError = AxiosError

export type ApiClientKnownErrorData = {
  code: string
  detail: string
  details: Record<string, unknown>
}

export type ApiClientKnownError = ApiClientError & {
  response: {
    data: ApiClientKnownErrorData
  }
}

export class ApiClient {
  private axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    if (!baseUrl) throw new Error('Base URL is required for API client')

    const client = axios.create({
      baseURL: baseUrl,
    })
    client.defaults.headers.common['Content-Type'] = 'application/json'

    this.axiosInstance = client
  }

  setAuthorizationHeader(token: string) {
    if (!this.axiosInstance)
      throw new Error('Axios instance is not initialized')

    if (!token) throw new Error('Token is required for setting auth header')

    this.axiosInstance.defaults.headers.common['Authorization'] =
      `Bearer ${token}`
  }

  async getAll<T>(
    resource: string,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await this.axiosInstance.get<T, AxiosResponse<T, unknown>>(
      resource,
      config,
    )
    return handleResponse<T>(response)
  }

  async get<T>(
    resource: string,
    resourceId: string,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await this.axiosInstance.get<T, AxiosResponse<T, unknown>>(
      buildPath([resource, resourceId]),
      config,
    )
    return handleResponse<T>(response)
  }

  async post<T, TData = unknown>(
    resource: string,
    data?: TData,
    config: AxiosRequestConfig = {},
  ) {
    const response = await this.axiosInstance.post<
      T,
      AxiosResponse<T, unknown>
    >(resource, data, config)
    return handleResponse<T>(response)
  }

  async put<T, TData>(
    resource: string,
    resourceId: string,
    data: TData,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await this.axiosInstance.put<T, AxiosResponse<T, unknown>>(
      buildPath([resource, resourceId]),
      data,
      config,
    )
    return handleResponse<T>(response)
  }

  async patch<T = void, TData = unknown>(
    resource: string,
    resourceId: string,
    data: TData,
    config: AxiosRequestConfig = {},
  ) {
    const response = await this.axiosInstance.patch<
      T,
      AxiosResponse<T, unknown>
    >(buildPath([resource, resourceId]), data, config)
    return handleResponse<T>(response)
  }

  async delete<T = void>(
    resource: string,
    resourceId: string,
    config: AxiosRequestConfig = {},
  ) {
    const response = await this.axiosInstance.delete(
      buildPath([resource, resourceId]),
      config,
    )
    return handleResponse<T>(response)
  }
}

export const apiClient = new ApiClient(API_URL)
