import React, { useCallback, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { usePathname } from 'next/navigation'
import Link from 'next/link'

import {
  TreeItem,
  TreeItemType,
  UIButton,
  UITooltip,
  UITree,
  UITreeInput,
  useToast,
} from '@/components/ui'
import { TreeItems } from '@/components/ui'
import {
  FolderAddIcon,
  FolderIcon,
  OpenFolderIcon,
  LabelIcon,
  StarIcon,
} from '@/components/ui/icons'
import { NavSection } from '@/components/shared'
import { useGetFavoritesNavigation } from '@/hooks/queries'
import {
  FavoritesNavigation as FavoritesNavigationResponse,
  ViewAsNavigationItem,
  ViewFolderAsNavigationItem,
} from '@/models/api'
import { ApiClientKnownError } from '@/services/api/client'
import { generateUuid } from '@/utils'
import { usePatchFavoritesNavigation } from '@/hooks/mutations'

interface FavoritesNavigationProps {
  isCompact: boolean
}

const ID_DELIMITER = '|'

const getSourceIdFromTreeItemId = (id: string | number) =>
  id.toString().split(ID_DELIMITER)[1]

const parseSourceIdToTreeItemId = (
  id: string | number,
  type: TreeItemType = 'item',
) => `${type === 'group' ? 'g' : 'i'}${ID_DELIMITER}${id}`

const parseTreeItemsToNavigationItems = (items: TreeItems) =>
  items.map(({ id: treeItemId, type, label, children, metadata }) => {
    const id = getSourceIdFromTreeItemId(treeItemId)
    const isGroup = type === 'group'

    return {
      id,
      type,
      label: isGroup ? label : undefined,
      color: isGroup ? metadata?.color : undefined,
      isPrivate: isGroup ? metadata?.isPrivate : undefined,
      children: isGroup
        ? children.map(subItem => {
            const subId = getSourceIdFromTreeItemId(subItem.id)
            return {
              id: subId,
              type: subItem.type,
            }
          })
        : null,
    } as ViewAsNavigationItem | ViewFolderAsNavigationItem
  })

const FavoritesNavigation = ({ isCompact }: FavoritesNavigationProps) => {
  const [isAdding, setIsAdding] = useState(false)

  const pathname = usePathname()

  const { toast } = useToast()

  const { data: favoritesNavigation, isLoading: isFirstLoading } =
    useGetFavoritesNavigation()

  const { mutateAsync: patchNavigation } = usePatchFavoritesNavigation()

  const patchNavigationHandler = useCallback(
    async (newItems: TreeItems) => {
      if (!favoritesNavigation) return

      const items: FavoritesNavigationResponse =
        parseTreeItemsToNavigationItems(newItems)

      try {
        await patchNavigation({
          id: favoritesNavigation.id,
          changes: {
            items,
          },
        })
      } catch (error) {
        const details =
          (error as ApiClientKnownError).response?.data?.detail || undefined
        toast.error('Failed to update favorites', { description: details })
      }
    },
    [favoritesNavigation, patchNavigation, toast],
  )

  const items = useMemo(() => {
    if (!favoritesNavigation) {
      return []
    }

    const mapViewItem = (view: ViewAsNavigationItem) =>
      ({
        id: parseSourceIdToTreeItemId(view.id, view.type),
        label: view.label,
        type: 'item',
        href: `/views/${view.id}`,
        children: [],
        Icon: LabelIcon,
      }) as TreeItem

    const favoritesTree: TreeItems = favoritesNavigation.items.map(item =>
      !(item as ViewFolderAsNavigationItem).children
        ? mapViewItem(item as ViewAsNavigationItem)
        : {
            id: parseSourceIdToTreeItemId(item.id, item.type),
            label: (item as ViewFolderAsNavigationItem).label,
            type: 'group',
            Icon: FolderIcon,
            ExpandedIcon: OpenFolderIcon,
            children: (item as ViewFolderAsNavigationItem).children.map(
              mapViewItem,
            ),
          },
    )
    return favoritesTree
  }, [favoritesNavigation])

  const createFolderHandler = useCallback(
    async (folderName: string) => {
      if (!favoritesNavigation || !favoritesNavigation.items) return

      await patchNavigation({
        id: favoritesNavigation.id,
        changes: {
          items: [
            {
              id: generateUuid(),
              label: folderName,
              type: 'group',
              children: [],
            },
            ...favoritesNavigation.items,
          ],
        },
      })
    },
    [favoritesNavigation, patchNavigation],
  )

  const toggleAdding = () => setIsAdding(prev => !prev)

  if (isFirstLoading || !items.length) return null

  return (
    <NavSection
      title="Favorites"
      data-adding={isAdding}
      isCompact={isCompact}
      CompactIcon={StarIcon}
      actions={({ setIsExpanded }) => (
        <UITooltip content="Add new folder" placement="bottom">
          <UIButton
            isIconOnly
            size="sm"
            variant="light"
            className="min-w-6 h-6 w-6 group-data-[adding=true]:hidden text-default-500 hover:text-default-foreground"
            onPress={() => {
              toggleAdding()
              setIsExpanded(true)
            }}
          >
            <FolderAddIcon className="h-4 w-4 text-inherit" />
          </UIButton>
        </UITooltip>
      )}
      topContent={
        <AnimatePresence>
          {isAdding && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="w-full pl-1 pr-px"
            >
              <div className="pt-2 ml-1.5">
                <UITreeInput
                  startContent={
                    <FolderAddIcon className="h-[22px] w-[22px] mr-1 ml-0.5 text-default-500" />
                  }
                  inputClassName="!px-0.5"
                  placeholder="Enter new folder name..."
                  onClose={toggleAdding}
                  onPressEnter={createFolderHandler}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      }
    >
      <UITree
        defaultItems={items}
        currentPath={pathname}
        LinkComponent={Link}
        isEditable={!isCompact}
        isCompact={isCompact}
        isSortable
        collapsible
        indentationWidth={30}
        onChange={patchNavigationHandler}
      />
    </NavSection>
  )
}

export default FavoritesNavigation
