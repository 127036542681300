import { Tooltip, TooltipProps } from '@nextui-org/tooltip'
import { Kbd, KbdProps } from '@nextui-org/kbd'
import { forwardRef } from 'react'

export interface UITooltipProps
  extends Omit<
    TooltipProps,
    'delay' | 'closeDelay' | 'shadow' | 'motionProps'
  > {
  children?: React.ReactNode
  shortcutText?: string
  shortcutKey?: KbdProps['keys']
}

const UITooltip = forwardRef<HTMLElement | null, UITooltipProps>(
  (
    {
      children,
      shortcutKey,
      shortcutText,
      content,
      classNames,
      ...props
    }: UITooltipProps,
    ref,
  ) => (
    <Tooltip
      ref={ref}
      delay={1000}
      closeDelay={0}
      shadow={undefined}
      size="sm"
      classNames={{
        ...classNames,
        content: [
          classNames?.content,
          'text-tiny shadow rounded ring-1 ring-default-200 font-normal text-default-500 bg-white dark:font-light dark:text-default-700 dark:bg-[#1C1C1E] dark:ring-default-300/60',
        ],
      }}
      motionProps={{
        variants: {
          exit: {
            opacity: 0,
            transition: {
              duration: 0.1,
              ease: 'easeIn',
            },
          },
          enter: {
            opacity: 1,
            transition: {
              duration: 0.1,
              ease: 'easeOut',
            },
          },
        },
      }}
      content={
        shortcutText || shortcutKey ? (
          <div className="flex items-center gap-x-2 text-tiny">
            {content}
            <Kbd
              keys={shortcutKey}
              classNames={{
                abbr: 'text-foreground-500 dark:text-foreground-400',
                base: 'text-tiny rounded-md text-foreground-500 dark:text-foreground-400',
              }}
            >
              {shortcutText}
            </Kbd>
          </div>
        ) : (
          content
        )
      }
      {...props}
    >
      {children}
    </Tooltip>
  ),
)

UITooltip.displayName = 'UITooltip'

export default UITooltip
