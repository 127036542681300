import React, { PropsWithChildren } from 'react'

import { useIsDarkTheme } from '@/hooks'
import { useGetFeatures } from '@/hooks/queries/features/useGetFeatures'
import { UIToaster } from '@/components/ui'

import { IdleTimerModal } from './IdleTimerModal'
import Sidebar from './Sidebar'

const PrivateLayout = ({ children }: PropsWithChildren) => {
  // TODO: should be in SSR once we move to App Router
  useGetFeatures()

  const { currentTheme } = useIsDarkTheme()

  return (
    <div className="relative flex h-full min-h-full w-full items-stretch overflow-hidden">
      <UIToaster theme={currentTheme} />

      <IdleTimerModal />

      <Sidebar />

      <div className="min-w-0 min-h-0 h-screen flex flex-col flex-1">
        {children}
      </div>
    </div>
  )
}

PrivateLayout.displayName = 'PrivateLayout'

export default PrivateLayout
