import { SupabaseUserMetadata } from '@/models'
import { noop } from '@/utils'
import { Session } from '@supabase/supabase-js'
import { createContext } from 'react'

export const AuthContext = createContext<{
  jwt?: string
  userMetadata?: SupabaseUserMetadata
  isInitialLogIn: boolean
  session: Session | null | undefined
  isSigningOut: boolean
  setIsSigningOut(value: boolean): void
  updateToken(
    newToken: string,
    onSuccess?: () => void,
    refreshToken?: string,
  ): void
  getCurrentToken(): string | undefined
}>({
  isInitialLogIn: true,
  session: null,
  updateToken: noop,
  isSigningOut: false,
  setIsSigningOut: noop,
  getCurrentToken: noop,
})
